import React from 'react';

import AppLayout from '../components/layout/AppLayout';
import { ConsentDetails } from '../components/old-version/ui/ConsentDetails/ConsentDetails';

const ConsentView = () => {
  return (
    <AppLayout>
      <ConsentDetails />
    </AppLayout>
  );
};

export default ConsentView;
